<template>
  <div class="chart-info summary-info w-full relative">
    <vx-card v-if="!isLoading" class="summary-info__card">
      <div class="summary-info__data">
        <ul class="summary-info__data__list">
          <li class="summary-info__data__list__item">
            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[0]) }}</div>
            <div class="summary-info__data__list__item__value">{{ sumTotalVegaVisitorsCountPerDay }}</div>
          </li>

          <li class="summary-info__data__list__item">
            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[1]) }}</div>
            <div class="summary-info__data__list__item__value">{{ sumThresholdVegaVisitorsCountPerDay }}</div>
          </li>

          <li class="summary-info__data__list__item">
            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[2]) }}</div>
            <div class="summary-info__data__list__item__value">{{ percentVegaVisitorsCountPerDay }}</div>
          </li>
        </ul>

        <!-- CHART -->
        <div v-if="!isVegaVisitorsCountPerDayDataLoading" class="summary-info__data__chart">
          <static-chart :series="totalVegaVisitorsCountPerDay" :xaxis="xaxisValues" :color="color" />

          <static-chart :series="thresholdVegaVisitorsCountPerDay" :xaxis="xaxisValues" :color="color" />
        </div>
      </div>

    </vx-card>

    <placeholder-text-small v-else />
  </div>
</template>
<script>
import { analyticsData } from './analyticsData'
import StaticChart from '@/components/static-chart/StaticChart.vue'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  components: {
    StaticChart,
    PlaceholderTextSmall
  },

  mixins: [analyticsData],

  props: {
    isLoading: {
      type: Boolean
    },
    isVegaVisitorsCountPerDayDataLoading: {
      type: Boolean
    },
    series: {
      type: Array
    },
    weekdays: {
      type: Array
    }
  },

  data() {
    return {
      demoSummaryDataLabels: ['Total Visitors', 'Visitors with 74%+ VEGA Score', '% of Visitors'],
      demoSummaryData: [
        {
          value: '123.000'
        },
        {
          value: '23.000'
        },
        {
          value: '18,7%'
        },
        // {
        //   value: '500'
        // },
      ],
      infoImg: require('@/assets/images/elements/info-light.svg'),
      color: '#f1a342'
    }
  },

  computed: {
    totalVegaVisitorsCountPerDay() {
      return [
        {
          data: this.series[0].totalData
        }
      ]
    },

    thresholdVegaVisitorsCountPerDay() {
      return [
        {
          data: this.series[0].thresholdData
        }
      ]
    },

    sumTotalVegaVisitorsCountPerDay() {
      if (this.series && this.series[0] && this.series[0].totalData) {
        return this.series[0].totalData.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      }

      return 0
    },

    sumThresholdVegaVisitorsCountPerDay() {
      if (this.series && this.series[0] && this.series[0].thresholdData) {
        return this.series[0].thresholdData.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      }

      return 0
    },

    percentVegaVisitorsCountPerDay() {
      let percent = Math.round((this.sumThresholdVegaVisitorsCountPerDay / this.sumTotalVegaVisitorsCountPerDay) * 100)

      if (isNaN(percent)) {
        percent = 0
      }

      percent = `${percent}%`

      return percent
    },

    summaryData() {
      if (!this.isVegaActivated) {
        return this.demoSummaryData
      }

      const summaryData = this.demoSummaryData

      let totalWebsiteVisitorsCount = 0
      let totalVisitorsWithBuyingIntentCount = 0
      let visitorsWithBuyingIntentInPercent = 0
      // let totalConversationRequestsCount = 0

      if (!summaryData || !(summaryData.length > 0)) {
        summaryData[0].value = totalWebsiteVisitorsCount
        summaryData[1].value = totalVisitorsWithBuyingIntentCount
        summaryData[2].value = visitorsWithBuyingIntentInPercent
        // summaryData[3].value = totalConversationRequestsCount

        return summaryData
      }

      if (this.vegaVisitorAnalytics) {
        totalWebsiteVisitorsCount = this.vegaVisitorAnalytics.reduce(
          (accumulator, currentValue) => accumulator + currentValue.visitor_count,
          0
        )

        const totalVisitorsWithBuyingIntentArray = this.vegaVisitorAnalytics.filter((item) => this.selectedVegaPopupThreshhold.includes(item.score_category))
        totalVisitorsWithBuyingIntentCount = totalVisitorsWithBuyingIntentArray.reduce(
          (accumulator, currentValue) => accumulator + currentValue.visitor_count,
          0
        )
        visitorsWithBuyingIntentInPercent = Math.round((totalVisitorsWithBuyingIntentCount / totalWebsiteVisitorsCount) * 100)
        if (isNaN(visitorsWithBuyingIntentInPercent)) {
          visitorsWithBuyingIntentInPercent = 0
        }
        visitorsWithBuyingIntentInPercent = `${visitorsWithBuyingIntentInPercent}%`
      }

      // if (this.vegaConversationStatus) {
      //   totalConversationRequestsCount = this.vegaConversationStatus.reduce(
      //     (accumulator, currentValue) => accumulator + currentValue.visitor_count,
      //     0
      //   )
      // }

      summaryData[0].value = totalWebsiteVisitorsCount
      summaryData[1].value = totalVisitorsWithBuyingIntentCount
      summaryData[2].value = visitorsWithBuyingIntentInPercent
      // summaryData[3].value = totalConversationRequestsCount

      return summaryData
    },
    xaxisValues() {
      return {
        categories: this.weekdays
      }
    },
    title() {
      return this.$i18n.t('views.home.customerReqeuestsCountPerDay')
    }
  }
}
</script>
<style lang="scss">
.apexcharts-datalabel-label {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
}
</style>

<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}

.summary-info {
  &__data {
    display: flex;

    &__list {
      padding-top: 80px;
      padding-right: 80px;
      min-width: 40%;

      &__item {
        display: flex;
        margin-top: 60px;

        &:first-of-type {
          margin-top: 0;
        }

        &:nth-of-type(2) {
          margin-top: 120px;
        }

        &__label {
          flex: 1;
          width: 200px;
          color: rgb(44, 44, 44);
          font-size: 28px;
          font-weight: 700;
        }

        &__value {
          margin-left: 80px;
          min-width: 40px;
          color: rgb(44, 44, 44);
          font-size: 28px;
          font-weight: 500;
        }
      }
    }

    &__chart {
      flex-grow: 1;
    }
  }
}
</style>
