<template>
  <div class="chart-info w-full relative">
    <vx-card>
      <div class="mb-5">
        <h3>Visitor Analytics</h3>
        <div>VEGA Score: Signaling Urgency for Action</div>
      </div>

      <template v-if="!isLoading">
        <div class="flex flex-row items-start">
          <div class="relative w-full">
            <vs-list-header :title="$t('views.home.visitorStatusOverview')" class="mb-0 pb-2 card-title-font-size" color="primary"></vs-list-header>
            <div class="chart-info__subtitle">{{ $t('views.home.visitorStatusOverviewSubtitle') }}</div>
          </div>
          <div class="ml-2 vs-list--header">
            <vx-tooltip style="width: 17px" :text="$t('info.dashboard.visitorStatusOverviewInfo')" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>
          </div>
        </div>

        <div class="visitor-status-list">
          <div v-for="item in visitorStatusOverviewData" :key="item.name" class="visitor-status-list__item">
            <VegaIcon :type="item.type" :width="38" />

            <div class="visitor-status-list__item__info">
              <div class="visitor-status-list__item__info__title">
                {{ item.name }}
              </div>
              <div class="visitor-status-list__item__info__description">{{ $t('views.home.reachedTheStatus') }}</div>
            </div>

            <div class="visitor-status-list__item__amount">{{ item.amount }}</div>
            <div class="visitor-status-list__item__percent">{{ item.percent }}%</div>

            <div class="visitor-status-list__item__detailed-description">{{ item.description }}</div>
          </div>
        </div>
      </template>

      <placeholder-text-small v-else />
    </vx-card>
  </div>
</template>

<script>
import { analyticsData } from './analyticsData'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'
import VegaIcon from './VegaIcon.vue'

export default {
  components: {
    PlaceholderTextSmall,
    VegaIcon
  },

  mixins: [analyticsData],

  props: {
    title: {
      type: String,
      default: 'Item Title'
    },
    isLoading: {
      type: Boolean
    }
  },

  data() {
    return {
      demoVisitorStatusOverviewData: [
        {
          type: 'onfire',
          name: 'On Fire',
          amount: '12.300',
          percent: '10,5',
          description: 'Highest rate of VEGA-Score.'
        },
        {
          type: 'hot',
          name: 'Hot',
          amount: '22.386',
          percent: '18,2',
          description: 'Significant VEGA-Score indicating sales potential.'
        },
        {
          type: 'warm',
          name: 'Warm',
          amount: '8.610',
          percent: '7',
          description: 'Moderate interest.'
        },
        {
          type: 'mild',
          name: 'Mild',
          amount: '37.023',
          percent: '30,1',
          description: 'Researching but no buying intent.'
        },
        {
          type: 'cold',
          name: 'Cold',
          amount: '28.905',
          percent: '23,5',
          description: 'Low interest, rare interaction.'
        },
        {
          type: 'frozen',
          name: 'Frozen',
          amount: '13.161',
          percent: '10,7',
          description: 'Very low interaction, inactive.'
        }
      ],
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },

  computed: {
    visitorStatusOverviewData() {
      if (!this.isVegaActivated) {
        return this.demoVisitorStatusOverviewData
      }

      const vegaData = this.vegaVisitorAnalytics

      let demoModifiedData = this.demoVisitorStatusOverviewData.map((item) => {
        item.percent = 0
        item.amount = 0

        return { ...item }
      })

      if (!vegaData) {
        return demoModifiedData
      }

      const totalVisitorsCount = vegaData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.visitor_count,
        0
      )

      vegaData.forEach((vegaDataItem) => {
        demoModifiedData = demoModifiedData.map((item) => {
          if (item && vegaDataItem && vegaDataItem.visitor_count && item.type === vegaDataItem.score_category) {
            item = {
              ...item,
              amount: item.amount + vegaDataItem.visitor_count
            }
          }

          return item
        })
      })

      demoModifiedData.forEach((item) => {
        let percent = 0

        if (totalVisitorsCount && item.amount) {
          const calculatedPercent = Math.round((item.amount / totalVisitorsCount) * 100)

          if (!isNaN(calculatedPercent)) {
            percent = calculatedPercent
          }
        }

        item.percent = percent
      })

      return demoModifiedData
    }
  }
}
</script>

<style lang="scss">
.apexcharts-datalabel-label {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
}
</style>
<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}
</style>

<style lang="scss">
.visitor-status-list {
  margin-top: 30px;

  &__item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }

    &__info {
      margin-left: 20px;
      margin-right: auto;
      text-align: left;

      &__title {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: rgb(118, 116, 128);
      }

      &__description {
        font-size: 13px;
        line-height: 16px;
        color: rgb(194, 192, 197);
      }
    }

    &__amount {
      margin-left: 20px;
      min-width: 25px;
      font-size: 13px;
      line-height: 16px;
      color: rgb(118, 116, 128);
    }

    &__percent {
      width: 50px;
      min-width: 50px;
      text-align: right;
      font-size: 13px;
      line-height: 16px;
      color: rgb(194, 192, 197);
    }

    &__detailed-description {
      flex-grow: 1;
      margin-left: 50px;
    }
  }
}
</style>
