var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "pt-5 analytics-container--right-grow1 vega-analytics-container",
      },
      [
        _c(
          "div",
          { staticClass: "ml-0 margin-rt" },
          [
            _c("VegaAnalyticsSummary", {
              attrs: {
                series: _vm.series,
                weekdays: _vm.weekdays,
                isLoading: _vm.isVegaVisitorAnalyticsDataLoading,
                isVegaVisitorsCountPerDayDataLoading:
                  _vm.isVegaVisitorsCountPerDayDataLoading,
              },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "analytics-container--right-grow1 vega-analytics-container",
      },
      [
        _c(
          "div",
          { staticClass: "ml-0 margin-rt" },
          [
            _c("VegaVisitorStatusOverview", {
              attrs: { isLoading: _vm.isVegaVisitorAnalyticsDataLoading },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "margin-lt" },
          [
            _c("VegaVisitorsWithBuyingIntent", {
              attrs: { isLoading: _vm.isVegaVisitorAnalyticsDataLoading },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "analytics-container--right-grow1 vega-analytics-container",
      },
      [
        _c(
          "div",
          { staticClass: "ml-0" },
          [
            _c("VegaScoresAnalytics", {
              attrs: { isLoading: _vm.isVegaScoresAnalyticsDataLoading },
            }),
          ],
          1
        ),
      ]
    ),
    _vm.selectedVegaUserFlow.url
      ? _c(
          "div",
          {
            staticClass:
              "ml-0 analytics-container--right-grow1 vega-analytics-container",
          },
          [
            _c(
              "div",
              { staticClass: "ml-0" },
              [
                _c("VegaUserFlow", {
                  attrs: { isLoading: _vm.isVegaVisitorScoreFlowDataLoading },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "analytics-container--right-grow1 vega-analytics-container",
      },
      [
        _c(
          "div",
          { staticClass: "ml-0 margin-rt" },
          [
            _c("VegaCampaigns", {
              attrs: { isLoading: _vm.isVegaCampaignsDataLoading },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }