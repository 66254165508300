<template>
  <div class="chart-info w-full relative vega-scores-analytics">
    <vx-card>
      <div class="data-block-heading">
        <h3
          class="data-block-heading__button"
          @click="$serverBus.$emit('fetch-data-in-blocks', { query: 'bq-scores-analytics' })"
        >
          <DownArrowIcon :width="14" :height="14" :style="`transform: rotate(${isCollapsedWebsiteAnalyticsBlock ? '0deg' : '180deg'}); margin-right: 12px;`" />
          Website Analytics
        </h3>
      </div>

      <template v-if="!isCollapsedWebsiteAnalyticsBlock">
        <div v-if="!isLoading">
          <vs-table :key="tableKey" ref="table" v-model="selected" @selected="setVegaUserFlowUrl" pagination :max-items="itemsPerPage" search :data="scoresAnalyticsData" :noDataText="$t('views.home.noDataAvailable')">
            <div slot="header" class="flex flex-column items-start flex-grow justify-center">
              <div class="mb-3 rounded-lg cursor-pointer flex w-full flex-row items-center justify-start text-base vega-scores-analytics__title">
                {{ $t('views.home.scoresAnalytics') }}
              </div>
            </div>

            <template slot="thead">
              <vs-th sort-key="url">URL</vs-th>
              <vs-th sort-key="totalVisitors">{{ $t('views.home.totalVisitors') }}</vs-th>
              <vs-th sort-key="score">{{ $t('views.home.averageScoreOnWebpage') }}</vs-th>
              <vs-th sort-key="score">{{ $t('views.home.highestScoreOnWebpage') }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td class="vega-scores-analytics__list-view--column vega-scores-analytics__list-view__url">
                    {{ tr.u }}
                  </vs-td>

                  <vs-td class="vega-scores-analytics__list-view--column">
                    {{ tr.t }}
                  </vs-td>

                  <vs-td class="vega-scores-analytics__list-view--column vega-scores-analytics__list-view__score">
                    <VisitorScoring :score="tr.a" :online="true" :isVegaActivated="true" :isVegaWidgetEnabled="true" />
                  </vs-td>

                  <vs-td class="vega-scores-analytics__list-view--column vega-scores-analytics__list-view__score">
                    <VisitorScoring :score="tr.m" :online="true" :isVegaActivated="true" :isVegaWidgetEnabled="true" />
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>

        <placeholder-text-small v-else />
      </template>
    </vx-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import { analyticsData } from './analyticsData'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'
import VisitorScoring from '@/components/vega/VisitorScoring.vue'

import DownArrowIcon from '@/components/icons/DownArrowIcon'

export default {
  components: {
    VisitorScoring,
    PlaceholderTextSmall,
    DownArrowIcon
  },

  mixins: [analyticsData],

  props: {
    isLoading: {
      type: Boolean
    }
  },

  data() {
    return {
      demoScoresAnalyticsData: [
        {
          u: 'www.letsconnect.at/ecommerce/campaign/landingpage',
          t: 11023,
          a: 6,
          m: 7
        },
        {
          u: 'www.letsconnect.at',
          t: 256,
          a: 3,
          m: 5
        },
        {
          u: 'www.letsconnect.at/ecommerce/',
          t: 1239,
          a: 2,
          m: 4
        }
      ],
      tableKey: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      selected: [],
      itemsPerPage: 5,
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },

  computed: {
    ...mapGetters({
      isCollapsedWebsiteAnalyticsBlock: 'dashboard/isCollapsedWebsiteAnalyticsBlock',
    }),

    scoresAnalyticsData() {
      if (!this.isVegaActivated) {
        return this.demoScoresAnalyticsData
      }

      if (!this.vegaScoresAnalytics) {
        return []
      }

      return this.vegaScoresAnalytics
        .map((item) => {
          return {
            ...item,
            a: Number(item.a),
            m: Number(item.m),
            t: item.t
          }
        })
        .sort((a, b) => (a.a > b.a ? -1 : 1))
    }
  },

  methods: {
    ...mapActions({
      updateSelectedVegaUserFlow: 'dashboard/updateSelectedVegaUserFlow'
    }),
    setVegaUserFlowUrl(data) {
      const { u: url, a: averageScore } = data

      if (!url) {
        return
      }

      this.updateSelectedVegaUserFlow({ url, averageScore })
    }
  }
}
</script>
<style lang="scss">
.data-block-heading {
  display: flex;

  &__button {
    cursor: pointer;
  }
}

.apexcharts-datalabel-label {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
}
</style>
<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}
</style>

<style lang="scss">
.vega-scores-analytics {
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: #262629;
  }

  .vs-table-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #262629;
    text-transform: uppercase;
  }

  &__list-view--column {
    word-break: break-all;
  }

  &__list-view {
    &__campaign {
      min-width: 150px;
    }

    &__url {
      min-width: 200px;
    }

    &__score {
      span {
        display: flex;
      }

      &__label {
        display: inline-block;
        margin-left: 14px;
        font-size: 17px;
        line-height: 21px;
        color: #726f7b;
        white-space: nowrap;
      }
    }
  }
}
</style>
