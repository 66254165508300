<template>
  <div>
    <div class="pt-5 analytics-container--right-grow1 vega-analytics-container">
      <div class="ml-0 margin-rt">
        <VegaAnalyticsSummary
          :series="series"
          :weekdays="weekdays"
          :isLoading="isVegaVisitorAnalyticsDataLoading"
          :isVegaVisitorsCountPerDayDataLoading="isVegaVisitorsCountPerDayDataLoading"
        />
      </div>
    </div>

    <div class="analytics-container--right-grow1 vega-analytics-container">
      <div class="ml-0 margin-rt">
        <VegaVisitorStatusOverview :isLoading="isVegaVisitorAnalyticsDataLoading" />
      </div>

      <div class="margin-lt">
        <VegaVisitorsWithBuyingIntent :isLoading="isVegaVisitorAnalyticsDataLoading" />
      </div>
    </div>

    <div class="analytics-container--right-grow1 vega-analytics-container">
      <div class="ml-0">
        <VegaScoresAnalytics :isLoading="isVegaScoresAnalyticsDataLoading" />
      </div>
    </div>

    <div v-if="selectedVegaUserFlow.url" class="ml-0 analytics-container--right-grow1 vega-analytics-container">
      <div class="ml-0">
        <VegaUserFlow :isLoading="isVegaVisitorScoreFlowDataLoading" />
      </div>
    </div>

    <div class="analytics-container--right-grow1 vega-analytics-container">
      <div class="ml-0 margin-rt">
        <VegaCampaigns :isLoading="isVegaCampaignsDataLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import { analyticsData } from './analyticsData'
import VegaAnalyticsSummary from './VegaAnalyticsSummary.vue'
import VegaVisitorsWithBuyingIntent from './VegaVisitorsWithBuyingIntent.vue'
import VegaVisitorStatusOverview from './VegaVisitorStatusOverview.vue'
import VegaScoresAnalytics from './VegaScoresAnalytics.vue'
import VegaCampaigns from './VegaCampaigns.vue'
import VegaUserFlow from './VegaUserFlow.vue'

export default {
  name: 'VegaAnalytics',

  mixins: [analyticsData],

  props: {
    isVegaVisitorAnalyticsDataLoading: {
      type: Boolean
    },
    isVegaVisitorsCountPerDayDataLoading: {
      type: Boolean
    },
    isVegaScoresAnalyticsDataLoading: {
      type: Boolean
    },
    isVegaCampaignsDataLoading: {
      type: Boolean
    },
    series: {
      type: Array
    },
    weekdays: {
      type: Array
    },
    isVegaVisitorScoreFlowDataLoading: {
      type: Boolean
    },
  },

  components: {
    VegaAnalyticsSummary,
    VegaVisitorsWithBuyingIntent,
    VegaVisitorStatusOverview,
    VegaScoresAnalytics,
    VegaCampaigns,
    VegaUserFlow
  }
}
</script>

<style lang="scss">
.vega-analytics-container {
  .margin-rt,
  .margin-lt {
    display: flex;

    .chart-info {
      .vx-card {
        height: 100%;
      }
    }
  }

  .vs-list--header,
  .vs-list--title {
    margin-bottom: 0 !important;
    padding: 0 !important;
    color: rgb(38, 38, 41) !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }

  .vs-list--header {
    padding-bottom: 5px !important;
  }

  .chart-info__subtitle {
    font-size: 13px;
    line-height: 16px;
    color: rgb(194, 192, 197) !important;
  }

  .summary-info {
    .vs-list--title {
      color: rgb(255, 255, 255) !important;
    }

    .chart-info__subtitle {
      color: rgb(255, 255, 255) !important;
    }
  }

  .con-vs-tooltip {
    padding-top: 2px;
  }
}
</style>

<style lang="scss" scoped>
.analytics-container {
  * {
    box-sizing: border-box;
  }
  display: flex;
  padding: 10px;
  margin-top: 10px;

  &--mobile {
    padding: 10px 0 0;
  }

  @media (min-width: 1024px) {
    flex-direction: row;

    .margin-rt {
      margin-right: 10px;
    }
    .margin-lt {
      margin-left: 10px;
    }

    &--left {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      display: flex;
      flex-direction: column;

      &-visitors {
        margin-bottom: 20px;
      }
      &-grow1 {
        display: flex;

        > div {
          flex-grow: 1;
          flex-basis: 0;
          flex-shrink: 0;
          margin-bottom: 20px;
        }
      }
    }

    &--right {
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      &-grow1 {
        display: flex;
        flex-direction: row;

        > div {
          flex-grow: 1;
          flex-basis: 0;
          flex-shrink: 0;
          margin-left: 20px;
          margin-bottom: 20px;
        }
      }

      &--visitors-height {
        height: 309.75px;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .margin-rt {
      margin-right: 0px;
    }
    .margin-lt {
      margin-left: 0px;
    }

    &--left {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      &-visitors {
        margin-bottom: 20px;
      }
      &-grow1 {
        > div {
          margin-bottom: 20px;
        }
      }
    }

    &--right {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;

      &-grow1 {
        > div {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>

