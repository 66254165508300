var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info summary-info w-full relative" },
    [
      !_vm.isLoading
        ? _c("vx-card", { staticClass: "summary-info__card" }, [
            _c("div", { staticClass: "summary-info__data" }, [
              _c("ul", { staticClass: "summary-info__data__list" }, [
                _c("li", { staticClass: "summary-info__data__list__item" }, [
                  _c(
                    "div",
                    { staticClass: "summary-info__data__list__item__label" },
                    [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[0])))]
                  ),
                  _c(
                    "div",
                    { staticClass: "summary-info__data__list__item__value" },
                    [_vm._v(_vm._s(_vm.sumTotalVegaVisitorsCountPerDay))]
                  ),
                ]),
                _c("li", { staticClass: "summary-info__data__list__item" }, [
                  _c(
                    "div",
                    { staticClass: "summary-info__data__list__item__label" },
                    [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[1])))]
                  ),
                  _c(
                    "div",
                    { staticClass: "summary-info__data__list__item__value" },
                    [_vm._v(_vm._s(_vm.sumThresholdVegaVisitorsCountPerDay))]
                  ),
                ]),
                _c("li", { staticClass: "summary-info__data__list__item" }, [
                  _c(
                    "div",
                    { staticClass: "summary-info__data__list__item__label" },
                    [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[2])))]
                  ),
                  _c(
                    "div",
                    { staticClass: "summary-info__data__list__item__value" },
                    [_vm._v(_vm._s(_vm.percentVegaVisitorsCountPerDay))]
                  ),
                ]),
              ]),
              !_vm.isVegaVisitorsCountPerDayDataLoading
                ? _c(
                    "div",
                    { staticClass: "summary-info__data__chart" },
                    [
                      _c("static-chart", {
                        attrs: {
                          series: _vm.totalVegaVisitorsCountPerDay,
                          xaxis: _vm.xaxisValues,
                          color: _vm.color,
                        },
                      }),
                      _c("static-chart", {
                        attrs: {
                          series: _vm.thresholdVegaVisitorsCountPerDay,
                          xaxis: _vm.xaxisValues,
                          color: _vm.color,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _c("placeholder-text-small"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }