var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info w-full relative vega-campaigns" },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "data-block-heading" }, [
            _c(
              "h3",
              {
                staticClass: "data-block-heading__button",
                on: {
                  click: function ($event) {
                    return _vm.$serverBus.$emit("fetch-data-in-blocks", {
                      query: "bq-campaign-analytics",
                    })
                  },
                },
              },
              [
                _c("DownArrowIcon", {
                  style:
                    "transform: rotate(" +
                    (_vm.isCollapsedCampaignsBlock ? "0deg" : "180deg") +
                    "); margin-right: 12px;",
                  attrs: { width: 14, height: 14 },
                }),
                _vm._v("\n        Campaign Analytics\n      "),
              ],
              1
            ),
          ]),
          !_vm.isCollapsedCampaignsBlock
            ? [
                !_vm.isLoading
                  ? _c(
                      "div",
                      [
                        _c(
                          "vs-table",
                          {
                            key: _vm.tableKey,
                            ref: "table",
                            attrs: {
                              pagination: "",
                              "max-items": _vm.itemsPerPage,
                              search: "",
                              data: _vm.campaignsData,
                              noDataText: _vm.$t("views.home.noDataAvailable"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var data = ref.data
                                    return [
                                      _c(
                                        "tbody",
                                        _vm._l(data, function (tr, indextr) {
                                          return _c(
                                            "vs-tr",
                                            {
                                              key: indextr,
                                              attrs: { data: tr },
                                            },
                                            [
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass:
                                                    "vega-campaigns__list-view--column",
                                                },
                                                [
                                                  _c("VegaSocialMediaIcon", {
                                                    attrs: { type: tr.s },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass:
                                                    "vega-campaigns__list-view--column vega-campaigns__list-view__campaign",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(tr.c) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass:
                                                    "vega-campaigns__list-view--column vega-campaigns__list-view__url",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(tr.u) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass:
                                                    "vega-campaigns__list-view--column",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(tr.t) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass:
                                                    "vega-campaigns__list-view--column vega-campaigns__list-view__score",
                                                },
                                                [
                                                  _c("VisitorScoring", {
                                                    attrs: {
                                                      score: tr.a,
                                                      online: true,
                                                      isVegaActivated: true,
                                                      isVegaWidgetEnabled: true,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              571374893
                            ),
                            model: {
                              value: _vm.selected,
                              callback: function ($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-column items-start flex-grow justify-center",
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mb-3 rounded-lg cursor-pointer flex w-full flex-row items-center justify-start text-base vega-campaigns__title",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("views.home.campaigns")) +
                                        "\n\n              "
                                    ),
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "ml-3",
                                        staticStyle: { width: "17px" },
                                        attrs: {
                                          text: _vm.$t(
                                            "info.dashboard.campaignsInfo"
                                          ),
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            width: "17",
                                            height: "17",
                                            src: _vm.infoImg,
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                _c(
                                  "vs-th",
                                  { attrs: { "sort-key": "source" } },
                                  [_vm._v(_vm._s(_vm.$t("vue.source")))]
                                ),
                                _c(
                                  "vs-th",
                                  { attrs: { "sort-key": "campaign" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("views.home.campaign"))
                                    ),
                                  ]
                                ),
                                _c("vs-th", { attrs: { "sort-key": "url" } }, [
                                  _vm._v("URL"),
                                ]),
                                _c(
                                  "vs-th",
                                  { attrs: { "sort-key": "totalVisitors" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("views.home.totalVisitors"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-th",
                                  { attrs: { "sort-key": "score" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "views.home.averageVisitorScoreFromCampaign"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _c("placeholder-text-small"),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }